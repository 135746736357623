/**
 * Enum representing different types of cookies used in the application.
 * The short codes (f, a, b, e) are likely abbreviations for their respective categories.
 *
 * - `f` stands for Functional
 * - `a` stands for Analytics
 * - `b` stands for Behavioral
 * - `e` stands for ErrorTracking
 *
 * These short codes are likely used for compact storage or to comply with cookie string formatting constraints.
 */
export enum CookieTypeEnum {
  Functional = 'f',
  Analytics = 'a',
  Behavioral = 'b',
  ErrorTracking = 'e',
}

export type CookieSettingType = {
  title: string;
  description: string;
  type: CookieTypeEnum;
  required?: boolean;
};

/**
 * Enum representing the accepted cookie policies.
 * - None = '' (No cookies are accepted)
 * - All = Combination of Functional, Analytics, Behavioral, and ErrorTracking cookies.
 */
export enum AcceptedCookiePolicyEnum {
  None = '',
  All = CookieTypeEnum.Functional +
    CookieTypeEnum.Analytics +
    CookieTypeEnum.Behavioral +
    CookieTypeEnum.ErrorTracking,
}

type Single =
  | CookieTypeEnum.Analytics
  | CookieTypeEnum.Behavioral
  | CookieTypeEnum.ErrorTracking
  | CookieTypeEnum.Functional;
type Double = `${Single}${Single}`;
type Triple = `${Single}${Single}${Single}`;
type Quad = `${Single}${Single}${Single}${Single}`;

export type AcceptedCookiePolicyType = Single | Double | Triple | Quad | '';
